import { captureRemixErrorBoundaryError } from "@sentry/remix";
// import { cssBundleHref } from "@remix-run/css-bundle";
import {
  MetaFunction,
  json,
  type LinksFunction,
  type LoaderFunctionArgs } from
"@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError } from
"@remix-run/react";
import * as Sentry from "@sentry/remix";
import { useEffect } from "react";
import { tryGetUserIdFromSession } from "./auth/auth.remix.server";
import { PublicEnvVars, getPublicEnvVars } from "./common/envVars.server";
import { getUserById } from "./features/users/data/users.queries.server";
import globalStyles from "./styles/global.css";
import { useIsHydrated } from "./toolkit/remix/useIsHydrated";

export const links: LinksFunction = () =>
[
{
  rel: "stylesheet",
  href: globalStyles
}
// cssBundleHref ? { rel: "preload", href: cssBundleHref, as: "style" } : null,
].filter(Boolean) as any;

export const meta: MetaFunction = () => {
  return [
  { title: "DoTadda Knowledge" },
  {
    name: "description",
    content:
    "Know your EDGE. DoTadda Knowledge helps you manage and consume the firehose of information, giving you the edge you need to outperform your competition."
  }];

};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  try {
    let { userId, gqlClient, plan, appRole } = await tryGetUserIdFromSession(
      request
    );
    let fullUser =
    userId && gqlClient ? await getUserById(gqlClient, userId) : null;

    if (fullUser?.id) {
      Sentry.setUser({
        id: fullUser?.id,
        username: fullUser?.name,
        email: fullUser?.email
      });
    }
    return json({
      user: userId ?
      {
        plan,
        appRole,
        ...fullUser
      } :
      null,
      ENV: {
        ...getPublicEnvVars(),
        SENTRY_MASKING_ENABLED: plan === "free" ? false : true
      }
    });
  } catch (error) {
    console.log("🚀 | root loader | error:", error);
  }
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  const data = useLoaderData<typeof loader>();
  const isHydrated = useIsHydrated();
  useEffect(() => {
    if (data.user?.id) {
      Sentry.setUser({
        id: data.user?.id,
        username: data.user?.name,
        email: data.user?.email
      });
    }
  }, [data?.user?.id]);
  return (
    <html lang="en" data-theme="dotadda" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`
          }} />

        <Outlet />
        <LiveReload />
        {isHydrated && <ScrollRestoration />}
        <Scripts />
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      </body>
    </html>);

}

export const getBrowserEnvVar = (
key: keyof PublicEnvVars,
fallback = "")
: string => {
  if (!key) return "";
  // @ts-ignore
  if (typeof window === "undefined") {
    return process.env[key] || "";
  }
  // @ts-ignore
  return window.ENV[key];
};